/** @jsxRuntime classic */
/** @jsx jsx */
import { motion } from "framer-motion"
import { graphql, Link, PageProps } from "gatsby"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import { Box, Container, Flex, Heading, jsx, Paragraph } from "theme-ui"

import { convertArticleData } from "helpers/index"
import type { HomePageQuery } from "../../graphql-types"

import AguilaButton from "components/AguilaButton"
import HomepageBlockMapper from "components/block-mappers/HomepageBlockMapper"
import LineAnimation from "components/common/LineAnimation"
import FeaturedPosts from "components/FeaturedPosts"
import Layout from "components/Layout"
import RetailerLocator from "components/Locator/RetailerLocator"
import ReadyToBuyCTA from "components/ReadyToBuyCTA"
import SectionHeading from "components/SectionHeading"
import BackgroundSVG from "images/icons/background-light.svg"
import { useEffect } from "react"

export interface HomePageProps extends PageProps {
  data: HomePageQuery
}

export const query = graphql`
  query HomePage {
    strapiHomepage {
      hero {
        title
      }
      seo {
        metaTitle
        metaDescription
        shareImage {
          localFile {
            publicURL
          }
        }
      }
      blocks
      pageImages {
        localFile {
          url
          childImageSharp {
            gatsbyImageData(width: 800)
          }
        }
      }
    }
    strapiGlobal {
      catalog {
        localFile {
          url
        }
      }
    }
    heroBannerText: file(
      relativePath: { eq: "proven-performance-hero-banner-text.png" }
    ) {
      id
      childImageSharp {
        gatsbyImageData(quality: 100)
      }
    }
    heroBannerMobile: file(
      relativePath: { eq: "proven-performance-hero-banner-text-mobile.png" }
    ) {
      id
      childImageSharp {
        gatsbyImageData(quality: 100)
      }
    }

    heroImage: file(relativePath: { eq: "hero-bullet-group-2x-trimmy.png" }) {
      id
      childImageSharp {
        gatsbyImageData(quality: 100)
      }
    }

    kaleighLawson: file(relativePath: { eq: "kaleigh-lawson.png" }) {
      id
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          quality: 100
          webpOptions: { quality: 90 }
          placeholder: DOMINANT_COLOR
        )
      }
    }
    fullWidthImage: file(
      relativePath: { eq: "homepage/homepage-le-full.png" }
    ) {
      id
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          quality: 100
          webpOptions: { quality: 90 }
          placeholder: DOMINANT_COLOR
        )
      }
    }
    miniShell: file(relativePath: { eq: "mini-shell.png" }) {
      id
      childImageSharp {
        gatsbyImageData(quality: 100)
      }
    }
    leOffset1: file(relativePath: { eq: "homepage/le-offset-1.png" }) {
      id
      childImageSharp {
        gatsbyImageData(quality: 100)
      }
    }
    leOffset2: file(relativePath: { eq: "homepage/le-offset-2.png" }) {
      id
      childImageSharp {
        gatsbyImageData(quality: 100)
      }
    }
    leOffset3: file(relativePath: { eq: "homepage/le-offset-3.png" }) {
      id
      childImageSharp {
        gatsbyImageData(quality: 100)
      }
    }
    allStrapiArticle(limit: 3) {
      edges {
        node {
          Publish_Date
          slug
          title
          article_type {
            slug
          }
        }
      }
    }
    instagram1: file(relativePath: { eq: "instagram-feed-1.png" }) {
      id
      childImageSharp {
        gatsbyImageData(quality: 90)
      }
    }
    instagram2: file(relativePath: { eq: "instagram-feed-2.png" }) {
      id
      childImageSharp {
        gatsbyImageData(quality: 90)
      }
    }
    instagram3: file(relativePath: { eq: "instagram-feed-3.png" }) {
      id
      childImageSharp {
        gatsbyImageData(quality: 90)
      }
    }
    instagram4: file(relativePath: { eq: "instagram-feed-4.png" }) {
      id
      childImageSharp {
        gatsbyImageData(quality: 90)
      }
    }
  }
`

const IndexPage = ({ data, ...props }: HomePageProps) => {
  const blogPosts = convertArticleData(data.allStrapiArticle?.edges)

  const instagramPosts = [
    {
      image: data.instagram1?.childImageSharp?.gatsbyImageData,
      imageAlt: "instagram image 1",
      linkUrl: "",
    },
    {
      image: data?.instagram2?.childImageSharp?.gatsbyImageData,
      imageAlt: "instagram image 2",
      linkUrl: "",
    },
    {
      image: data?.instagram3?.childImageSharp?.gatsbyImageData,
      imageAlt: "instagram image 3",
      linkUrl: "",
    },
    {
      image: data?.instagram4?.childImageSharp?.gatsbyImageData,
      imageAlt: "instagram image 4",
      linkUrl: "",
    },
  ]

  return (
    <Layout seo={data?.strapiHomepage?.seo} location={props.location}>
      <Box sx={{ position: "relative", overflow: "hidden" }}>
        <BackgroundSVG
          sx={{
            position: "absolute",
            zIndex: -1,
            transformOrigin: "center",
            bottom: 0,
            left: 0,
            width: "auto",
            height: "100%",
            transform: "translate(-40%, 0)",
            "& > g": {
              opacity: 0.2,
            },
            "& *": {
              fill: "#d6d1c4cf",
            },
          }}
        />
        <Box
          as="section"
          sx={{
            display: ["none", "block", "block", "block", "block"],
          }}
        >
          <Box
            sx={{
              width: "100%",
              background: `linear-gradient(90deg, rgba(255,255,255,0) 50%, #D6D1C4 50%)`,
              py: 12,
              pb: [24, null, 48, null, null],
              position: "relative",
            }}
          >
            <LineAnimation variant="primary" />
            <Box
              sx={{
                width: "full",
                height: "100%",
                position: "relative",
                zIndex: 3,
              }}
            >
              <Flex
                sx={{
                  width: "100%",
                  justifyContent: "center",
                  pl: [2, null, 4],
                }}
              >
                <GatsbyImage
                  image={data?.heroBannerText?.childImageSharp?.gatsbyImageData}
                  alt=""
                  sx={{
                    ml: "120px",
                    transform: [
                      null,
                      "translate(-90px)",
                      "translate(-110px)",
                      "translate(-130px)",
                    ],
                  }}
                />
              </Flex>
              <Container variant="layout.wide" sx={{ py: 12 }}>
                <Paragraph
                  as="h2"
                  variant="title"
                  sx={{ maxWidth: ["60%", null, "580px", null, null], mb: 16 }}
                >
                  {data.strapiHomepage?.hero?.title}
                </Paragraph>
                <RetailerLocator />
              </Container>
            </Box>
          </Box>
          <Flex sx={{ width: "100%", justifyContent: "flex-end", mb: 14 }}>
            <GatsbyImage
              image={data?.heroImage?.childImageSharp?.gatsbyImageData}
              alt="bullets image"
              sx={{
                width: "80%",
                maxWidth: "620px",
                alignSelf: "flex-end",
                mt: [-24, -44, null, null, -56],
                mr: [0, null, null, 8, null],
              }}
            />
          </Flex>
        </Box>

        {/* Mobile */}
        <Box
          as="section"
          sx={{
            display: ["block", "none", "none", "none", "none"],
            paddingTop: 0,
          }}
        >
          <Box
            sx={{
              width: "100%",
              background: `linear-gradient(90deg, rgba(255,255,255,0) 50%, #D6D1C4 50%)`,
              py: 12,
              pb: [24, null, 48, null, null],
              position: "relative",
              paddingTop: 0,
            }}
          >
            <LineAnimation variant="primary" />
            <Box
              sx={{
                width: "full",
                height: "100%",
                position: "relative",
                zIndex: 3,
              }}
            >
              <Flex>
                <Container
                  variant="layout.wide"
                  sx={{ py: 12, display: "flex", alignItems: "center" }}
                >
                  <Paragraph
                    as="h2"
                    variant="title"
                    sx={{ maxWidth: ["90%", null, "580px", null, null], pb: 0 }}
                  >
                    {data.strapiHomepage?.hero?.title}
                  </Paragraph>
                </Container>
                <Container
                  sx={{
                    padding: 0,
                  }}
                >
                  <Flex
                    sx={{
                      justifyContent: "center",
                      height: "100%",
                      alignItems: "center",
                    }}
                  >
                    <GatsbyImage
                      image={
                        data?.heroBannerMobile?.childImageSharp?.gatsbyImageData
                      }
                      alt=""
                      sx={{
                        height: "auto",
                        maxHeight: "100%",
                        width: "90%",
                        aspectRatio: "1",
                      }}
                    />
                  </Flex>
                </Container>
              </Flex>
              <Flex
                sx={{
                  justifyContent: "center",
                }}
              >
                <RetailerLocator
                  sx={{
                    maxWidth: "90%",
                  }}
                />
              </Flex>
            </Box>
          </Box>
          <Flex sx={{ width: "100%", justifyContent: "flex-end", mb: 14 }}>
            <GatsbyImage
              image={data?.heroImage?.childImageSharp?.gatsbyImageData}
              alt="bullets image"
              sx={{
                width: "80%",
                maxWidth: "620px",
                alignSelf: "flex-end",
                mt: [-24, -44, null, null, -56],
                mr: [0, null, null, 8, null],
              }}
            />
          </Flex>
        </Box>

        <Box as="section">
          <SectionHeading>Optimal Consistency</SectionHeading>
          <Container>
            <Paragraph sx={{ maxWidth: "700px", mb: [8, null, 14] }}>
              They crave lead and feast on recoil. They are born with a
              gluttonous appetite for speed and accuracy. We believe that
              getting the most out of your firearm begins with what you put into
              your firearm. And that’s why we only source the best raw materials
              to manufacture the best ammunition possible. Let the feeding
              frenzy begin.
            </Paragraph>
            <Flex
              sx={{
                flexWrap: "wrap",
                justifyContent: "start",
                maxWidth: "600px",
                gap: 5,
              }}
            >
              {/* <AguilaButton text="View All Ammunition" url="/ammunition/all" /> */}
              <AguilaButton
                text="View All Ammunition"
                variant="primary"
                url={"/ammunition/all"}
                sx={{
                  width: ["225px", null, "250px"],
                  textAlign: "center",
                }}
              />
              <AguilaButton
                text="Download our catalog"
                url={`${data?.strapiGlobal?.catalog?.localFile?.url}`}
                variant="secondary"
                sx={{
                  width: ["225px", null, "250px"],
                  textAlign: "center",
                }}
              />
            </Flex>
            <Flex
              sx={{
                variant: "layout.wide",
                justifyContent: "space-between",
                py: 16,
                alignItems: "flex-end",
                gap: 6,
              }}
            >
              <Link
                to="/ammunition/rimfire"
                sx={{
                  textDecoration: "none",
                }}
              >
                <motion.div
                  whileHover={{
                    y: ["0%", "-10%", "0%"],
                    transition: { duration: 2, repeat: Infinity },
                  }}
                >
                  <StaticImage
                    src="../images/homepage/rimfire-home-2x.png"
                    alt=""
                    layout="constrained"
                    quality={100}
                    height={382}
                  />
                  <Heading
                    as="h3"
                    variant="title"
                    sx={{
                      color: "primary",
                      textAlign: "center",
                      mt: 3,
                      zIndex: 1,
                    }}
                  >
                    Rimfire
                  </Heading>
                </motion.div>
              </Link>
              <Link
                to="/ammunition/handgun"
                sx={{
                  textDecoration: "none",
                }}
              >
                <motion.div
                  whileHover={{
                    y: ["0%", "-10%", "0%"],
                    transition: { duration: 2, repeat: Infinity },
                  }}
                >
                  <StaticImage
                    src="../images/homepage/handgun-home-2x.png"
                    alt=""
                    layout="constrained"
                    quality={100}
                    height={345}
                  />
                  <Heading
                    as="h3"
                    variant="title"
                    sx={{
                      color: "primary",
                      textAlign: "center",
                      mt: 3,
                      zIndex: 1,
                    }}
                  >
                    Handgun
                  </Heading>
                </motion.div>
              </Link>
              <Link
                to="/ammunition/rifle"
                sx={{
                  textDecoration: "none",
                }}
              >
                <motion.div
                  whileHover={{
                    y: ["0%", "-10%", "0%"],
                    transition: { duration: 2, repeat: Infinity },
                  }}
                >
                  <StaticImage
                    src="../images/homepage/rifle-home-2x.png"
                    alt=""
                    layout="constrained"
                    quality={100}
                    height={441}
                  />
                  <Heading
                    as="h3"
                    variant="title"
                    sx={{
                      color: "primary",
                      textAlign: "center",
                      mt: 3,
                      zIndex: 1,
                    }}
                  >
                    Rifle
                  </Heading>
                </motion.div>
              </Link>
              <Link
                to="/ammunition/shotshell"
                sx={{
                  textDecoration: "none",
                }}
              >
                <motion.div
                  whileHover={{
                    y: ["0%", "-10%", "0%"],
                    transition: { duration: 2, repeat: Infinity },
                  }}
                >
                  <StaticImage
                    src="../images/homepage/shotshell-home-2x.png"
                    alt=""
                    layout="constrained"
                    quality={100}
                    height={396}
                  />
                  <Heading
                    as="h3"
                    variant="title"
                    sx={{
                      color: "primary",
                      textAlign: "center",
                      mt: 3,
                      zIndex: 1,
                    }}
                  >
                    Shotshell
                  </Heading>
                </motion.div>
              </Link>
            </Flex>
          </Container>
        </Box>
      </Box>

      {data.strapiHomepage?.blocks.map((block: any, index: number) => (
        <HomepageBlockMapper
          key={index}
          instagramPosts={instagramPosts}
          block={block}
          images={data.strapiHomepage?.pageImages}
        />
      ))}

      <FeaturedPosts posts={blogPosts} />

      <ReadyToBuyCTA />
    </Layout>
  )
}

export default IndexPage
